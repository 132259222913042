//import { google } from '@agm/core/services/google-maps-types';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { throwToolbarMixedModesError } from '@angular/material';
import { DeviceDetectorService } from 'ngx-device-detector';
import { RestauranteService } from 'src/app/services/restaurante.service';
import { Filtro } from 'src/app/models/filtro';
import { TranslateService } from '@ngx-translate/core';

import { GLOBAL } from '../../../services/global';

declare const google: any;

@Component({
  selector: 'app-restaurantes',
  templateUrl: './restaurantes.component.html',
  styleUrls: ['./restaurantes.component.css']
})


export class RestaurantesComponent implements OnInit {

  map: any = null;
  isMobile: any;
  restaurantsArray: any = [];
  productosArray: any = [];
  platosArray: any = [];
  markers: Array<any> = [];
  pos: any = null;
  seccionItem: string;
  iconMyLocation: string = "/assets/img/my_location.png";
  iconComercio: string = "/assets/icons/comercio_.png";
  iconHosteleria: string = "/assets/icons/hosteleria_.png";
  iconMarca: string = "/assets/icons/marca.png";
  iconCercaDeMi: string = "/assets/icons/cerca_de_mi.png";
  infowindow: any = null;
  pantallaCarga: boolean = false;
  lang: string = "en";

  url = "";
  mensajeBusqueda = "";
  direccionTexto = "";
  permisoUbicacion = false;

  @Input() item = '';
  @Input() searchBar = '';
  @Input() searchHastang = [];
  @Input() filtro: Filtro;
  @Output() changeMensajeBusqueda = new EventEmitter<string>();
  @Output() changeCloseSearch = new EventEmitter<boolean>();

  constructor(
    private deviceDetector: DeviceDetectorService,
    private resService: RestauranteService,
    private translate: TranslateService
  ) {

  }

  ngOnInit() {
    this.url = GLOBAL.url;
    this.isMobile = this.deviceDetector.isMobile();
    this.lang = navigator.language.split('-')[0];
  }

  ngAfterViewInit(): void {
    //this.allRestaurants(this.item);
  }

  ngOnChanges(changes: any) {
    this.restaurantsArray = [];
    this.seccionItem = changes.item ? changes.item.currentValue : this.item;

    if (this.searchBar != "" && !changes.item) {
      this.changeCloseSearch.emit(false);
    }

    this.allRestaurants(this.seccionItem);
  }

  allRestaurants = (tipo) => {
    this.pantallaCarga = true;
    this.chargeMap();

    switch (tipo) {
      case 'carta': {
        this.resService.getAllRestaurantsCarta(this.searchBar, this.searchHastang, this.filtro).subscribe(result => {
          if (result["result"] == "ok") {
            this.platosArray = result["platos"];
            this.runPrintMapPlatos(this.platosArray);
            this.sendMensajeBusqueda();
          }

          this.pantallaCarga = false;

        });
        break;
      }
      case 'productos': {
        this.resService.getAllRestaurantsProducto(this.searchBar, this.searchHastang, this.filtro).subscribe(result => {

          if (result["result"] == "ok") {

            this.productosArray = result["productos"];
            this.runPrintMapProductos(this.productosArray);
            this.sendMensajeBusqueda();
          }

          this.pantallaCarga = false;

        });
        break;
      }
      case 'restaurantes': {
        this.resService.getAllRestaurantsRestaurante(this.searchBar, this.searchHastang, this.filtro).subscribe(result => {

          if (result["result"] == "ok") {

            this.restaurantsArray = result["establecimientos"];
            this.runPrintMap();
            this.sendMensajeBusqueda();
          }

          this.pantallaCarga = false;

        });
        break;
      }
      case 'usuarios': {
        this.resService.getAllRestaurantsUsuario(this.searchBar, this.searchHastang, this.filtro).subscribe(result => {

          if (result["result"] == "ok") {
            this.restaurantsArray = result["establecimientos"];
            this.runPrintMap();
            this.sendMensajeBusqueda();
          }

          this.pantallaCarga = false;

        });
        break;
      }
    }

  }

  chargeMap = () => {
    this.map = new google.maps.Map(document.getElementById('map'), {
      zoom: 13,
      streetViewControl: true,
      clickableIcons: false,
      mapTypeControl: false
    });

    this.addButtonGeolocation();

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.permisoUbicacion = true;
        this.pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };

        new google.maps.Marker({
          position: this.pos,
          map: this.map,
          icon: {
            url: this.iconMyLocation,
            size: new google.maps.Size(20, 20),
            scaledSize: new google.maps.Size(20, 20),
            labelOrigin: new google.maps.Point(20, -20)
          },
        });
      });
    } else {
      alert("Browser doesn't support Geolocation");
    }

  }

  runPrintMap = () => {
    let centrado = false;
    this.deleteMarkers();
    for (let i = 0; i < (<any>this.restaurantsArray).length; i++) {
      let restaurant = this.restaurantsArray[i];

      if (restaurant.lat != null && restaurant.lat != undefined &&
        restaurant.lng != null && restaurant.lng != undefined) {
        let pos = {
          lat: parseFloat(restaurant.lat),
          lng: parseFloat(restaurant.lng)
        };
        if (!centrado) {
          if (this.permisoUbicacion) {
            this.map.setCenter(pos);
          } else {
            setTimeout(() => {
              this.map.setCenter(pos);
              this.map.setZoom(13);
            }, 50);
          }

          centrado = true;
        }

        this.markers[restaurant.id] = new google.maps.Marker({
          position: pos,
          map: this.map,
          icon: {
            url: this.getTipoIcon(restaurant),
            size: new google.maps.Size(40, 40),
            scaledSize: new google.maps.Size(40, 40),
            labelOrigin: new google.maps.Point(40, -40)
          },
        });

        this.markers[restaurant.id].addListener("click", () => {
          this.selectedDevice(restaurant);
          this.infoWindow(restaurant, this.markers[restaurant.id]);
        });
      }
    }

    if (this.pos == null) {
      this.seeAllMarker();
    }
  }

  runPrintMapPlatos = (arrayElementos) => {
    let centrado = false;

    this.deleteMarkers();

    for (let i = 0; i < (<any>arrayElementos).length; i++) {
      let plato = arrayElementos[i];

      this.traducir(plato);

      if (plato.restaurante.lat != null && plato.restaurante.lat != undefined &&
        plato.restaurante.lng != null && plato.restaurante.lng != undefined) {
        let pos = {
          lat: parseFloat(plato.restaurante.lat),
          lng: parseFloat(plato.restaurante.lng)
        };
        if (!centrado) {
          if (this.permisoUbicacion) {
            this.map.setCenter(pos);
          } else {
            setTimeout(() => {
              this.map.setCenter(pos);
              this.map.setZoom(13);
            }, 50);
          }
          centrado = true;
        }

        if (this.markers[plato.restaurante.id] == undefined || this.markers[plato.restaurante.id] == null) {
          this.markers[plato.restaurante.id] = new google.maps.Marker({
            icon: {
              url: this.getTipoIcon(plato.restaurante),
              size: new google.maps.Size(40, 40),
              scaledSize: new google.maps.Size(40, 40),
              labelOrigin: new google.maps.Point(40, -40)
            },
            position: { lat: parseFloat(plato.restaurante.lat), lng: parseFloat(plato.restaurante.lng) },
            map: this.map
          });

          this.markers[plato.restaurante.id].addListener("click", () => {
            this.selectedDevice(plato);
            this.infoWindow(plato, this.markers[plato.restaurante.id]);
          });
        }

      }

    }

    if (this.pos == null) {
      this.seeAllMarker();
    }


  }

  runPrintMapProductos = (productos) => {
    let centrado = false;

    this.deleteMarkers();

    productos.forEach((producto) => {

      if (producto.mostrarEstablecimientos == 1) {

        producto.establecimientos.forEach((establecimiento) => {
          let pos = {
            lat: parseFloat(establecimiento.lat),
            lng: parseFloat(establecimiento.lng)
          };

          if (!centrado) {
            if (this.permisoUbicacion) {
              this.map.setCenter(pos);
            } else {
              setTimeout(() => {
                this.map.setCenter(pos);
                this.map.setZoom(13);
              }, 50);
            }
            centrado = true;
          }

          this.markers[establecimiento.id] = new google.maps.Marker({
            position: { lat: parseFloat(establecimiento.lat), lng: parseFloat(establecimiento.lng) },
            map: this.map,
            icon: {
              url: this.getTipoIcon(establecimiento),
              size: new google.maps.Size(40, 40),
              scaledSize: new google.maps.Size(40, 40),
              labelOrigin: new google.maps.Point(40, -40)
            },
          });

          this.markers[establecimiento.id].addListener("click", () => {
            this.map.setCenter(pos);
            this.infoWindow(producto, this.markers[establecimiento.id], establecimiento);
          });

        });
      }

      if (producto.mostrarFabricante == 1) {

        let pos = {
          lat: parseFloat(producto.fabricante.lat),
          lng: parseFloat(producto.fabricante.lng)
        };

        if (!centrado) {
          if (this.permisoUbicacion) {
            this.map.setCenter(pos);
          } else {
            setTimeout(() => {
              this.map.setCenter(pos);
              this.map.setZoom(13);
            }, 50);
          }
          centrado = true;
        }

        this.markers["f" + producto.fabricante.id] = new google.maps.Marker({
          position: { lat: parseFloat(producto.fabricante.lat), lng: parseFloat(producto.fabricante.lng) },
          map: this.map,
          icon: {
            url: this.iconMarca,
            size: new google.maps.Size(40, 40),
            scaledSize: new google.maps.Size(40, 40),
            labelOrigin: new google.maps.Point(40, -40)
          },
        });

        this.markers["f" + producto.fabricante.id].addListener("click", () => {
          this.map.setCenter(pos);
          this.infoWindow(producto, this.markers["f" + producto.fabricante.id], producto.fabricante);
        });
      }

    });

    if (this.pos == null) {
      this.seeAllMarker();
    }
  }

  getTipoIcon = (restaurant) => {

    let icon = null;

    if (restaurant.tiposlocales.length > 0) {

      let entidad = restaurant.tiposlocales[0].tipo_entidad.id;

      switch (entidad) {
        case 1: {
          icon = this.iconHosteleria;
          break;
        }
        case 2: {
          icon = this.iconComercio;
          break;
        }
      }
    }

    return icon;
  }


  seeAllMarker() {
    let bounds = new google.maps.LatLngBounds();

    for (let i = 0; i < this.restaurantsArray.length; i++) {
      if (this.markers[this.restaurantsArray[i].id_restaurante] != undefined) {
        bounds.extend(this.markers[this.restaurantsArray[i].id_restaurante].getPosition());
      }
    }

    this.productosArray.forEach((producto, index) => {
      if (producto.mostrarEstablecimientos == 1) {
        producto.establecimientos.forEach((establecimiento, index) => {
          if (this.markers[establecimiento.id_restaurante] != undefined) {
            bounds.extend(this.markers[establecimiento.id_restaurante].getPosition());
          }
        });
      }

      if (producto.mostrarFabricante == 1) {
        if (this.markers["f" + producto.fabricante.id] != undefined) {
          bounds.extend(this.markers["f" + producto.fabricante.id].getPosition());
        }
      }
    });

    this.map.fitBounds(bounds);
  }

  selectedDevice(device: any) {
    if (this.markers[device.id_restaurante] != undefined) {
      var pos = {
        lat: parseFloat(device.restaurante.lat),
        lng: parseFloat(device.restaurante.lng)
      };
      //this.map.setZoom(20);
      this.map.setCenter(pos);
    }
  }

  sendMensajeBusqueda = () => {

    let tieneDatos = true;

    switch (this.seccionItem) {
      case 'carta': {
        if (this.platosArray.length == 0) {
          tieneDatos = false;
        }
        break;
      }
      case 'productos': {
        if (this.productosArray.length == 0) {
          tieneDatos = false;
        }
        break;
      }
      case 'restaurantes':
      case 'usuarios': {
        if (this.restaurantsArray.length == 0) {
          tieneDatos = false;
        }
        break;
      }
    }

    this.mensajeBusqueda = "";
    let textoBuscando = "";
    let textoNoEncuentra = "";

    this.translate.get("buscador.estas_buscando").subscribe((res: string) => {
      textoBuscando = res;
    });

    this.translate.get("buscador.no_encontrado").subscribe((res: string) => {
      textoNoEncuentra = res;
    });

    if (this.searchBar.length > 0) {
      this.mensajeBusqueda += textoBuscando + ": <span style='color:#E35100;'>" + this.searchBar + "</span>";
    }

    if (!tieneDatos) {
      this.mensajeBusqueda += "<br>" + textoNoEncuentra;
    }

    this.changeMensajeBusqueda.emit(this.mensajeBusqueda);
  }

  infoWindow(device, marker, establecimiento = null) {

    let textoBotonVer = '';

    this.translate.get("mapa-marca.tarjeta.boton-ver").subscribe((res: string) => {
      textoBotonVer = res;
    });

    switch (this.seccionItem) {
      case 'carta': {
        var plato = device;
        var nombreRestaurante = device.restaurante.nombre;

        var contenidoInfo = '<div style="display:flex;flex-direction:column;">' +
          '<div style="flex:1;">' +
          '<div style="background-color:rgba(255,255,255,0.6);width:30px;height:30px;right:0;top:0;position:absolute;z-index:-1;border-radius:4px;"></div>' +
          '<div style="position: relative;z-index:-2;"><img style="width: 100%;height: 100%;object-fit: contain;" onclick="window.location.href = \'plato/' + plato.slug + '\';" src="' + GLOBAL.url + encodeURIComponent(plato.img) + '" onerror="this.onerror=null; this.src=\'' + GLOBAL.url + 'no-photo-available.png\'" /></div>' +
          '</div>' +
          '<div style="flex:1;margin-top: 4px;display: flex;flex-direction: column;">' +
          '<label style="margin-left: 3px; font-size: 15px;font-weight: bold;flex:1;margin-bottom: 0px;">' +
          plato.nombre_plato +
          '</label>' +
          '<label style="margin-left: 3px;font-size: 14px;color: #E35100;margin-top:4px;">' +
          '<b>' + nombreRestaurante + '</b>' +
          '</label>';
        this.codeAddress(plato.restaurante.lng, plato.restaurante.lat).then(() => {
          contenidoInfo += '<div>' + this.direccionTexto + '</div>';

          contenidoInfo +=
            '<div style="color: white;border-radius: 0px;background-color: #E35100;border-radius: 4px;border: none;padding-top: 5px;margin-top: 1px;padding-bottom: 5px;font-size: 16px;display: flex;justify-content: center;align-items: center;" onclick="window.location.href = \'plato/' + plato.slug + '\';">' + textoBotonVer + ' +</div>' +
            '</div>'
          '</div>';

          this.addInfoWindow(marker, contenidoInfo);
        });


        break;
      }
      case 'productos': {
        var producto = device;
        var contenidoInfo = '<div style="display:flex;flex-direction:column;">' +
          '<div style="background-color:rgba(255,255,255,0.6);width:30px;height:30px;right:0;top:0;position:absolute;z-index:-1;border-radius:4px;"></div>'
          + '<div style="position: relative;z-index:-2;">';
        if (this.searchHastang.length > 0) {
          if (establecimiento.articulo != null && establecimiento.articulo != undefined) {
            contenidoInfo += '<img style="width: 100%;height: 100%;object-fit: contain;" onclick="window.location.href = \'plato/' + establecimiento.articulo.slug + '\';" src="' + GLOBAL.url + establecimiento.articulo.img + '" onerror="this.onerror=null; this.src=\'' + GLOBAL.url + 'no-photo-available.png\'" />';
          } else {
            contenidoInfo += '<img style="width: 100%;height: 100%;object-fit: contain;" onclick="window.location.href = \'establecimiento/' + establecimiento.slug + '\';" src="' + GLOBAL.url + establecimiento.fotoPerfil + '" onerror="this.onerror=null; this.src=\'' + GLOBAL.url + 'no-photo-available.png\'" />';
          }
        } else {
          contenidoInfo += '<img style="width: 100%;height: 100%;object-fit: contain;" onclick="window.location.href = \'producto/' + producto.slug + '\';" src="' + GLOBAL.url + producto.img + '" onerror="this.onerror=null; this.src=\'' + GLOBAL.url + 'no-photo-available.png\'" />';
        }

        contenidoInfo +=
          '</div>' +
          '<div style="flex:1;margin-top: 4px;display: flex;flex-direction: column;">' +
          '<label style="margin-left: 3px; font-size: 15px;font-weight: bold;flex:1;margin-bottom: 0px;">';
        if (this.searchHastang.length > 0) {
          if (establecimiento.articulo != null && establecimiento.articulo != undefined) {
            contenidoInfo += establecimiento.articulo.nombre_plato;
          } else {
            contenidoInfo += producto.nombre;
          }
        } else {
          contenidoInfo += producto.nombre;
        }
        contenidoInfo +=
          '</label>' +
          '<div style="display: flex;flex-direction: column;">' +
          '<label style="margin-left: 3px;font-size: 14px;color: #E35100;margin-top:4px;">' +
          '<b>' + establecimiento.nombre + '</b>' +
          '</label>';

        this.codeAddress(establecimiento.lng, establecimiento.lat).then(() => {
          contenidoInfo += '<div>' + this.direccionTexto + '</div>';
          contenidoInfo +=
            '</div>';
          if (this.searchHastang.length > 0) {
            if (establecimiento.articulo != null && establecimiento.articulo != undefined) {
              contenidoInfo += '<div style="color: white;border-radius: 0px;background-color: #E35100;border-radius: 4px;border: none;padding-top: 5px;margin-top: 1px;padding-bottom: 5px;font-size: 16px;display: flex;justify-content: center;align-items: center;" onclick="window.location.href = \'plato/' + establecimiento.articulo.slug + '\';">' + textoBotonVer + ' +</div>';
            } else {
              contenidoInfo += '<div style="color: white;border-radius: 0px;background-color: #E35100;border-radius: 4px;border: none;padding-top: 5px;margin-top: 1px;padding-bottom: 5px;font-size: 16px;display: flex;justify-content: center;align-items: center;" onclick="window.location.href = \'establecimiento/' + establecimiento.slug + '\';">' + textoBotonVer + ' +</div>';
            }
          } else {
            contenidoInfo += '<div style="color: white;border-radius: 0px;background-color: #E35100;border-radius: 4px;border: none;padding-top: 5px;margin-top: 1px;padding-bottom: 5px;font-size: 16px;display: flex;justify-content: center;align-items: center;" onclick="window.location.href = \'producto/' + producto.slug + '\';">' + textoBotonVer + ' +</div>';
          }
          contenidoInfo +=
            '</div>' +
            '</div>';

          this.addInfoWindow(marker, contenidoInfo);
        });



        break;
      }
      case 'restaurantes': {

        var contenidoInfo = '<div style="display:flex;flex-direction:column;">' +
          '<div style="flex:1;">' +
          '<div style="background-color:rgba(255,255,255,0.6);width:30px;height:30px;right:0;top:0;position:absolute;z-index:-1;border-radius:4px;"></div>' +
          '<div style="position: relative;z-index:-2;"><img style="width: 100%;height: 100%;object-fit: contain;" onclick="window.location.href = \'establecimiento/' + device.slug + '\';" src="' + GLOBAL.url + device.fotoPerfil + '" onerror="this.onerror=null; this.src=\'' + GLOBAL.url + 'no-photo-available.png\'" /></div>' +
          '</div>' +
          '<div style="flex:1;margin-top: 4px;display: flex;flex-direction: column;">' +
          '<label style="margin-left: 3px; font-size: 15px;font-weight: bold;flex:1;margin-bottom: 0px;">' +
          device.nombre +
          '</label>' +
          '<label style="margin-left: 3px;font-size: 14px;color: #E35100;margin-top:4px;">' +
          '<b>' + this.getTipoServicio(device) + '</b>' +
          '</label>';
        this.codeAddress(device.lng, device.lat).then(() => {
          contenidoInfo += '<div>' + this.direccionTexto + '</div>';

          contenidoInfo += '<div style="color: white;border-radius: 0px;background-color: #E35100;border-radius: 4px;border: none;padding-top: 5px;margin-top: 1px;padding-bottom: 5px;font-size: 16px;display: flex;justify-content: center;align-items: center;" onclick="window.location.href = \'establecimiento/' + device.slug + '\';">' + textoBotonVer + ' +</div>' +
            '</div>' +
            '</div>';

          this.addInfoWindow(marker, contenidoInfo);
        });


        break;
      }
      case 'usuarios': {
        var contenidoInfo = '<div style="display:flex;">' +
          '<div style="flex:1;">' +
          '<img style="width: 100%;height: 100%;object-fit: contain;" src="' + GLOBAL.url + device.fotoPerfil + '" onerror="this.onerror=null; this.src=\'' + GLOBAL.url + 'no-photo-available.png\'" />' +
          '</div>' +
          '<div style="flex:1;margin-left: 10px;">' +
          '<label style="margin-left: 0px; font-size: 15px;font-weight: bold;flex:1;margin-bottom: 0px;">' +
          device.nombre +
          '</label>' +
          '<p style="margin-top: 10px;font-size: 12px;color: #E35100;">' +
          '<b>' + device.tipo_local + '</b>' +
          '</p>' +
          '<p style="font-size: 12px;color: #E35100;">' +
          '<b>' + device.tipo_comidas + '</b>' +
          '</p>' +
          '<p>' +
          '<b>' + device.direccion + '</b>' +
          '</p>' +
          '<div style="color:white;padding: 4px;border-radius: 0px; background-color:#E35100" onclick="window.location.href = \'establecimiento/' + device.slug + '\';">Ver Carta</div>' +
          '</div>'
        '</div>';
        break;
      }
    }

  }

  getTipoServicio(device) {
    let tipoServicio = "";

    if (this.lang != "es") {
      if (device.tiposlocales[0]["tipo_entidad"]["traduccion"][this.lang] != undefined) {
        let traduccionTipoEntidad = device.tiposlocales[0]["tipo_entidad"]["traduccion"][this.lang]["nombre"];

        if (traduccionTipoEntidad != null && traduccionTipoEntidad != "") {
          tipoServicio = traduccionTipoEntidad;
        } else {
          tipoServicio = device.tipo_servicio;
        }
      } else {
        tipoServicio = device.tipo_servicio;
      }
    } else {
      tipoServicio = device.tipo_servicio;
    }

    return tipoServicio;
  }

  addInfoWindow(marker, contenidoInfo) {

    if (this.infowindow == null) {
      this.infowindow = new google.maps.InfoWindow();
    }

    this.infowindow.setContent(contenidoInfo);
    this.infowindow.setOptions({ maxWidth: 170 });
    this.infowindow.open(this.map, marker);
  }

  async codeAddress(long: any, lat: any) {

    let textoBotonIrA = "";

    this.translate.get("mapa-marca.tarjeta.boton-ir-a").subscribe((res: string) => {
      textoBotonIrA = res;
    });

    if (this.pos != null) {
      return new Promise<void>((resolve, reject) => {
        var service = new google.maps.DistanceMatrixService();

        var html = '';
        this.direccionTexto = "";

        var origen = new google.maps.LatLng(this.pos.lat, this.pos.lng);
        var destino = new google.maps.LatLng(lat, long);

        service.getDistanceMatrix(
          {
            origins: [origen],
            destinations: [destino],
            travelMode: google.maps.TravelMode.WALKING,
            unitSystem: google.maps.UnitSystem.METRIC,
            avoidHighways: false,
            avoidTolls: false,

          }, (response, status) => {
            if (status == 'OK') {
              console.log("aqui entro");
              html += '<div style="display: flex;align-items: center;">';
              html += '<div style="display: flex;justify-content: center;width:80px;color:#E35100;border-radius: 0px;background-color: #FFF;border:solid 2px #E35100;border-radius: 4px;padding: 0px 0px 0px 4px;font-weight: bold;font-size: 18px;" onclick="window.open(\'https://www.google.com/maps/dir/?api=1&origin=' + this.pos.lat + ',' + this.pos.lng + '&destination=' + lat + ',' + long + '&travelmode=walking\');">' + textoBotonIrA + '<img style="width:23px;" src=\'../../../assets/img/google_map.png\' /></div>';
              html += '<label style="margin-bottom: 0px;margin-left: 10px;flex:1;"><b>A ' + response.rows[0].elements[0].distance.text + '</b></label>';
              html += '</div>';
              html += '<div style="color: green;margin-bottom: 0px;margin-top: 1px;display: flex;align-items: center;">';
              html += '<img style="width: 15%;height: 100%;object-fit: cover;" src="../../../assets/icons/icono_andando.png" />'
              html += '<label style="margin-bottom:0px;">' + response.rows[0].elements[0].duration.text + '</label>';
              html += '</div>';

            }

            this.direccionTexto = html;
            resolve();
          });
      });
    } else {
      var html = '';
      html += '<div style="margin-bottom: 0px;margin-top: 1px;display: flex;padding-left:5px;    flex-direction: column;">';
      html += '<div style="display: flex;justify-content: center;width:80px;color:#E35100;border-radius: 0px;background-color: #FFF;border:solid 2px #E35100;border-radius: 4px;padding: 0px 0px 0px 4px;font-weight: bold;font-size: 18px;" onclick="window.open(\'https://www.google.com/maps/dir/?api=1&destination=' + lat + ',' + long + '&travelmode=walking\');">' + textoBotonIrA + '<img style="width:23px;" src=\'../../../assets/img/google_map.png\' /></div>';
      html += '<p><b>Sin permisos en ubicación</b></p>';
      html += '</div>';
      this.direccionTexto = html;
    }

  }

  /*deleteMarkers() {

    for (let marker of this.markers) {
      if (marker != undefined) {
        marker.setMap(null);
      }
    }
    this.markers = [];
  }*/

  deleteMarkers() {
    // Iterar sobre las claves del objeto markers
    for (let markerKey in this.markers) {
      if (this.markers.hasOwnProperty(markerKey)) {
        // Obtener el marcador actual
        let marker = this.markers[markerKey];
        // Verificar si el marcador es válido
        if (marker) {
          // Eliminar el marcador del mapa
          marker.setMap(null);
        }
      }
    }
    // Reiniciar el objeto markers
    this.markers = [];
  }

  addButtonGeolocation() {
    var geolocateButton = document.createElement('div');
    geolocateButton.style.backgroundImage = 'url("' + this.iconCercaDeMi + '")';
    geolocateButton.style.backgroundSize = 'cover';
    geolocateButton.style.cursor = "pointer";
    geolocateButton.style.width = "40px";
    geolocateButton.style.height = "40px";
    geolocateButton.style.marginRight = "10px";
    geolocateButton.addEventListener("click", () => {
      this.map.setCenter(this.pos);
      this.map.setZoom(15);
    });
    // Añadir el botón de geolocalización al mapa
    this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(geolocateButton);
  }

  traducir = (device) => {
    if (this.lang != "es") {

      if (device["traduccion"][this.lang] != undefined) {

        let tradNombrePlato = device["traduccion"][this.lang].nombre_plato;

        if (tradNombrePlato != "" && tradNombrePlato != null) {
          device.nombre_plato = tradNombrePlato;
        }

      }

      if (device.restaurante["traduccion"][this.lang] != undefined) {

        let tradNombre = device.restaurante["traduccion"][this.lang].nombre;

        if (tradNombre != "" && tradNombre != null) {
          device.restaurante.nombre = tradNombre;
        }

      }

    }
  }
}


