import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Filtro } from 'src/app/models/filtro';
import { Restaurante } from 'src/app/models/restaurante';
import { RestauranteService } from 'src/app/services/restaurante.service';
import { PlatosService } from 'src/app/services/platos.service';
import { ProductoService } from 'src/app/services/producto.service';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { GLOBAL } from '../../../services/global';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatSelectionList, MatListOption } from '@angular/material/list';

@Component({
  selector: 'app-seccion-carta',
  templateUrl: './seccion-carta.component.html',
  styleUrls: ['./seccion-carta.component.css']
})
export class SeccionCartaComponent implements OnInit {

  url = "";
  restaurante: Restaurante;
  platos = [];
  isMobile: boolean = false;
  imgSeccion = [];

  valorFiltro: MatListOption;
  labeFiltro: any;
  tagFiltro: any;
  page: number = 1;
  pagHasta: number = 0;
  numPages: number = 1;
  goScroll: boolean = false;
  cambiadoBusqueda = false;
  sizeBusqueda = 0;

  @Input() item = '';
  @Input() searchBar = '';
  @Input() searchHastang = [];
  @Input() filtro: Filtro;
  @Input() nextPage = false;
  @Input() pos = null;

  mensajeBusqueda = "";

  @Output() changeMensajeBusqueda = new EventEmitter<string>();
  @Output() changeCloseSearch = new EventEmitter<boolean>();
  @Output() changeStatePage = new EventEmitter<boolean>();
  @Output() scrollData = new EventEmitter();


  constructor(
    private deviceDetector: DeviceDetectorService,
    private pService: PlatosService,
    private prService: ProductoService,
    private uService: UsuariosService,
    private rService: RestauranteService,
    private router: Router,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef) {
    this.restaurante = new Restaurante;

  }

  async ngOnInit() {

    this.url = GLOBAL.url;
    this.isMobile = this.deviceDetector.isMobile();
  }

  @Input() isFirstRun;
  async ngOnChanges(changes: any) {

    var seccionItem = changes.item ? changes.item.currentValue : this.item;

    if (this.searchBar != "" && !changes.item) {
      this.changeCloseSearch.emit(false);
    }

    if (this.nextPage || changes.searchBar || changes.searchHastang || changes.item || changes.pos) {

      if (changes.searchBar || changes.searchHastang) {
        this.cambiadoBusqueda = true;
        if (changes.searchBar) {
          this.sizeBusqueda = changes.searchBar.currentValue.length;
        }

      } else {
        this.cambiadoBusqueda = false;
      }

      if (!this.nextPage || changes.item || changes.pos) {
        this.page = 1;
        this.numPages = 1;
        this.imgSeccion = [];
      } else {
        this.page++;
      }

      this.actualizarNavegacion();

      this.pagHasta = 0;

      let navegacion: any = localStorage.getItem("navegacion");

      if (navegacion != null) {
        navegacion = JSON.parse(navegacion);
        if (this.imgSeccion.length == 0) {
          this.pagHasta = navegacion.page;
          this.page = navegacion.page;
          this.numPages = navegacion.page;
          this.changeStatePage.emit(false);
          this.goScroll = true;
        }
      }

      if (changes.pos) {
        setTimeout(async () => {
          switch (seccionItem) {
            case 'carta': { await this.generarImagenesSeccionesCarta(this.searchBar, this.searchHastang, this.filtro); break; }
            case 'productos': { await this.generarImagenesSeccionesProductos(this.searchBar, this.searchHastang, this.filtro); break; }
            case 'restaurantes': { await this.generarImagenesSeccionesRestaurantes(this.searchBar, this.searchHastang, this.filtro); break; }
            case 'usuarios': { await this.generarImagenesSeccionesUsuarios(this.searchBar, this.searchHastang, this.filtro); break; }
          }
        }, 100);

      } else {
        switch (seccionItem) {
          case 'carta': { await this.generarImagenesSeccionesCarta(this.searchBar, this.searchHastang, this.filtro); break; }
          case 'productos': { await this.generarImagenesSeccionesProductos(this.searchBar, this.searchHastang, this.filtro); break; }
          case 'restaurantes': { await this.generarImagenesSeccionesRestaurantes(this.searchBar, this.searchHastang, this.filtro); break; }
          case 'usuarios': { await this.generarImagenesSeccionesUsuarios(this.searchBar, this.searchHastang, this.filtro); break; }
        }
      }


    }

    localStorage.setItem("filtro", JSON.stringify(this.filtro));

  }

  generarImagenesSeccionesCarta = async (searchBar, searchHastang, filtros) => {
    if (this.page == 1 || this.page <= this.numPages) {

      await this.pService.getPhotosPlatos(searchBar, searchHastang, filtros, true, this.page, this.pagHasta, this.pos).subscribe(result => {
        if (result.result == "ok") {
          let noHacerNada = false;

          if (this.cambiadoBusqueda) {

            if (this.sizeBusqueda != searchBar.length) {
              noHacerNada = true;
            } else {
              this.imgSeccion = [];
            }

          }

          if (!noHacerNada) {
            this.numPages = result.platos.last_page;
            for (let valor of Object.values(result.platos.data)) {
              this.imgSeccion.push({
                thumbImage: GLOBAL.url + valor['img']
                , guidPlato: valor['guid']
                , idPlato: valor['id']
                , slugPlato: valor['slug']
              });
            }
            this.changeStatePage.emit(false);
            this.sendMensajeBusqueda();

            if (this.goScroll) {
              setTimeout(() => {
                this.scrollData.emit();
                this.goScroll = false;
              }, 10);
            }
          }


        }
      });
    }

  };

  generarImagenesSeccionesProductos = async (searchBar, searchHastang, filtros) => {
    if (this.page == 1 || this.page <= this.numPages) {
      await this.prService.getPhotosProductos(searchBar, searchHastang, filtros, true, this.page, this.pagHasta, this.pos).subscribe(result => {

        if (result.result == "ok") {

          let noHacerNada = false;

          if (this.cambiadoBusqueda) {

            if (this.sizeBusqueda != searchBar.length) {
              noHacerNada = true;
            } else {
              this.imgSeccion = [];
            }

          }

          if (!noHacerNada) {
            this.numPages = result.productos.last_page;
            for (let valor of Object.values(result.productos.data)) {
              this.imgSeccion.push({
                thumbImage: GLOBAL.url + valor['img']
                , guidProducto: valor['guid']
                , idProducto: valor['id']
                , slugProducto: valor['slug']
              });
            }
            this.changeStatePage.emit(false);
            this.sendMensajeBusqueda();

            if (this.goScroll) {
              setTimeout(() => {
                this.scrollData.emit();
                this.goScroll = false;
              }, 10);
            }
          }

        }
      });
    }

  };

  generarImagenesSeccionesRestaurantes = async (searchBar, searchHastang, filtros) => {
    if (this.page == 1 || this.page <= this.numPages) {
      await this.rService.getPhotosRestaurantes(searchBar, searchHastang, filtros, true, this.page, this.pagHasta, this.pos).subscribe(result => {
        if (result.result == "ok") {

          let noHacerNada = false;

          if (this.cambiadoBusqueda) {

            if (this.sizeBusqueda != searchBar.length) {
              noHacerNada = true;
            } else {
              this.imgSeccion = [];
            }

          }

          if (!noHacerNada) {
            let data;
            if (result.establecimientos.data != undefined) {
              this.numPages = result.establecimientos.last_page;
              data = result.establecimientos.data;
            } else {
              this.page = 1;
              this.numPages = 1;
              this.imgSeccion = [];
              data = result.establecimientos;
            }

            for (let valor of Object.values(data)) {
              this.imgSeccion.push({
                thumbImage: GLOBAL.url + valor['fotoPerfil']
                , guidPlato: valor['guid']
                , idPlato: valor['id']
                , slugPlato: valor['slug']
              });
            }
            this.changeStatePage.emit(false);
            this.sendMensajeBusqueda();

            if (this.goScroll) {
              setTimeout(() => {
                this.scrollData.emit();
                this.goScroll = false;
              }, 10);
            }
          }

        }
      });
    }

  };

  generarImagenesSeccionesUsuarios = async (searchBar, searchHastang, filtros) => {
    if (this.page == 1 || this.page <= this.numPages) {
      await this.uService.getPhotosUsuarios(searchBar, searchHastang, filtros, true, this.page).subscribe(result => {
        if (result.result == "ok") {
          this.numPages = result.publicaciones.last_page;
          for (let valor of Object.values(result.publicaciones.data)) {
            this.imgSeccion.push({
              thumbImage: GLOBAL.url + valor['img']
              , guidPlato: valor['guid']
              , idPlato: valor['id']
              , slugPlato: valor['slug']
            });
          }
          this.changeStatePage.emit(false);
          this.sendMensajeBusqueda();
        }
      });
    }

  };

  goDetailPlato(idTipo, item) {
    let navegacion: any = localStorage.getItem("navegacion");

    if (navegacion != null) {
      navegacion = JSON.parse(navegacion);
      navegacion.searchBar = this.searchBar;
      navegacion.searchHastang = this.searchHastang;
      localStorage.setItem("navegacion", JSON.stringify(navegacion));
    }

    switch (item) {
      case 'carta': { this.router.navigate(['plato/' + idTipo]); break; }
      case 'productos': { this.router.navigate(['producto/' + idTipo]); break; }
      case 'restaurantes': { this.router.navigate(['establecimiento/' + idTipo]); break; }
      case 'usuarios': { this.router.navigate(['publicacion/' + idTipo]); break; }
    }
  }

  sendMensajeBusqueda = () => {

    this.mensajeBusqueda = "";
    let textoBuscando = "";
    let textoNoEncuentra = "";

    this.translate.get("buscador.estas_buscando").subscribe((res: string) => {
      textoBuscando = res;
    });

    this.translate.get("buscador.no_encontrado").subscribe((res: string) => {
      textoNoEncuentra = res;
    });

    if (this.searchBar.length > 0) {
      this.mensajeBusqueda += textoBuscando + ": <span style='color:#E35100;'>" + this.searchBar + "</span>";
    }

    if (this.imgSeccion.length == 0) {
      this.mensajeBusqueda += "<br>" + textoNoEncuentra;
    }

    this.changeMensajeBusqueda.emit(this.mensajeBusqueda);
  }

  actualizarNavegacion() {
    let navegacion: any = localStorage.getItem("navegacion");

    if (navegacion != null) {
      navegacion = JSON.parse(navegacion);
      navegacion.page = this.page;
      navegacion.searchBar = this.searchBar;
      localStorage.setItem("navegacion", JSON.stringify(navegacion));
    }
  }

}

