import { InfoRestauranteComponent } from './components/info-restaurante/info-restaurante.component';
import { PaginaPlatoComponent } from './components/pagina-plato/pagina-plato.component';
import { PaginaBarComponent } from './components/pagina-bar/pagina-bar.component';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MapaComponent } from './components/mapa/mapa.component';
import { AgmCoreModule } from '@agm/core';
import { BuscadorComponent } from './components/buscador/buscador.component';
import { InfoBoxComponent } from './components/info-box/info-box.component';
import { HeaderBarComponent } from './components/general/header-bar/header-bar.component';
import { ModalMensajeComponent } from './components/general/modal-mensaje/modal-mensaje.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SeccionCategoriasComponent } from './components/seccion-categorias/seccion-categorias.component';
import { SeccionResultadosComponent } from './components/seccion-resultados/seccion-resultados.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material';
import { SliderRangeComponent } from './components/slider-range/slider-range.component';
import { Ng5SliderModule } from 'ng5-slider';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { MatExpansionModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { NgImageSliderModule } from 'ng-image-slider';
import { ModalModule } from './../app/components/_modal/modal.module';
import { CommonModule } from '@angular/common';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ReservasComponent } from './components/reservas/reservas.component';
import { NgxDaterangepickerMd } from 'ngx-datepicker-material';
import { ReactiveFormsModule } from '@angular/forms';
import { QRCodeModule } from 'angular2-qrcode';
import { ScheduleModule } from '@syncfusion/ej2-angular-schedule';
import { NgSelectModule, NgSelectConfig } from '@ng-select/ng-select';
import { ɵs } from '@ng-select/ng-select';
import { LoginComponent } from './components/login/login.component';
import { RegistrarseComponent } from './components/registrarse/registrarse.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { NO_ERRORS_SCHEMA } from '@angular/core';
import { PerfilComponent } from './components/perfil/perfil.component';
import { EditarPerfilComponent } from './components/editar-perfil/editar-perfil.component';
import { PublicacionComponent } from './components/publicacion/publicacion.component';
import { FooterComponent } from './components/general/footer/footer.component';
import { SeccionCartaComponent } from './components/gallery/seccion-carta/seccion-carta.component';
import { RestaurantesComponent } from './components/mapa/restaurantes/restaurantes.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { Select2Module } from 'ng2-select2';
import { BrowserModule } from '@angular/platform-browser';
import { PaginaProductoComponent } from './components/pagina-producto/pagina-producto.component';
import { PaginaFabricanteComponent } from './components/pagina-fabricante/pagina-fabricante.component';
import { InfoFabricanteComponent } from './components/info-fabricante/info-fabricante.component';
import { PaginaUsuarioComponent } from './components/pagina-usuario/pagina-usuario.component';
import { InfoUsuarioComponent } from './components/info-usuario/info-usuario.component';
import { MapaMarcaComponent } from './components/mapa-marca/mapa-marca.component';
import { RecuperarPassComponent } from './components/recuperar-pass/recuperar-pass.component';
import { ModalConfirmComponent } from './components/general/modal-confirm/modal-confirm.component';
import { LoadingComponent } from './components/general/loading/loading.component';
import { CreatePageComponent } from './components/create-page/create-page.component';
import { ModalMensajeUrlComponent } from './components/general/modal-mensaje-url/modal-mensaje-url.component';
import { RatingComponent } from './components/rating/rating.component';
import { AngularCropperjsModule } from 'angular-cropperjs';
import { DatosEnvioComponent } from './components/datos-envio/datos-envio.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';


@NgModule({
  declarations: [
    AppComponent,
    RegistrarseComponent,
    MapaComponent,
    BuscadorComponent,
    InfoBoxComponent,
    HeaderBarComponent,
    ModalMensajeComponent,
    SeccionCategoriasComponent,
    SeccionResultadosComponent,
    SliderRangeComponent,
    PaginaBarComponent,
    PaginaPlatoComponent,
    InfoRestauranteComponent,
    ReservasComponent,
    LoginComponent,
    PerfilComponent,
    EditarPerfilComponent,
    PublicacionComponent,
    FooterComponent,
    SeccionCartaComponent,
    RestaurantesComponent,
    AccordionComponent,
    PaginaProductoComponent,
    PaginaFabricanteComponent,
    InfoFabricanteComponent,
    PaginaUsuarioComponent,
    InfoUsuarioComponent,
    MapaMarcaComponent,
    RecuperarPassComponent,
    ModalConfirmComponent,
    LoadingComponent,
    CreatePageComponent,
    ModalMensajeUrlComponent,
    RatingComponent,
    DatosEnvioComponent,
  ],
  imports: [
    HttpClientModule,
    ModalModule,
    CommonModule,
    AppRoutingModule,
    AngularCropperjsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBWGXPZ2vLs5nMVpl0xN5_r283Mxk5nyNU'
    }),
    NgbModule,
    MatGridListModule,
    MatCardModule,
    MatIconModule,
    Ng5SliderModule,
    MatExpansionModule,
    BrowserAnimationsModule,
    MatListModule,
    MatRadioModule,
    MatSliderModule,
    MatInputModule,
    NgSelectModule,
    FormsModule,
    NgImageSliderModule,
    NgxMaterialTimepickerModule,
    NgxDaterangepickerMd,
    ReactiveFormsModule,
    QRCodeModule,
    ScheduleModule,
    /*MDBBootstrapModule.forRoot()*/
    Select2Module,
    BrowserModule,
    /*TranslateModule.forRoot({
      defaultLanguage: 'es',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),*/
  ],
  exports: [
    ModalMensajeComponent,
    LoadingComponent,
    ModalConfirmComponent,
  ],
  entryComponents: [ModalMensajeComponent, ModalConfirmComponent, ModalMensajeUrlComponent, LoadingComponent],
  providers: [
    MapaComponent,
    RegistrarseComponent,
    InfoBoxComponent,
    DeviceDetectorService,
    SeccionResultadosComponent,
    NgSelectConfig,
    ɵs
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule { }

/*export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/translate/', '.json');
}*/
