import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestauranteService } from 'src/app/services/restaurante.service';
import { Restaurante } from 'src/app/models/restaurante';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { GLOBAL } from '../../services/global';
import { rest } from 'lodash';
import { FollowService } from 'src/app/services/follow.service';
import { MetricaService } from 'src/app/services/metrica.service';
import { faCamera } from '@fortawesome/fontawesome-free';

@Component({
  selector: 'app-info-restaurante',
  templateUrl: './info-restaurante.component.html',
  styleUrls: ['./info-restaurante.component.css']
})

export class InfoRestauranteComponent implements OnInit {

  arrayBotones = [];
  arrayFiltros = [];
  slug: any;
  id: any;
  restaurante: Restaurante;
  closeResult: string;
  url = "";
  cartaQR: boolean = false;
  followRestaurant: boolean = false;
  resultInsertFollowRestaurant: any;
  userLogin: any;
  resultInsertFollow: any;
  urlContactoWhatsapp: string = "";
  pos: any = null;
  pantallaCarga: boolean = false;
  posRestaurante: any = null;
  hosteleria: boolean = false;
  iconComercio: string = "/assets/icons/comercio_info.png";
  iconHosteleria: string = "/assets/icons/hosteleria_info.png";
  iconRuta: string = "/assets/icons/ruta_info.png";
  diasSemana = [
    'lunes',
    'martes',
    'miercoles',
    "jueves",
    "viernes",
    "sabado",
    "domingo"
  ];
  diaSemanaHoy: any = 0;
  verTodoHorario = false;

  constructor(
    private route: ActivatedRoute,
    private rService: RestauranteService,
    private modalService: NgbModal,
    private followService: FollowService,
    private mService: MetricaService,
    private router: Router
  ) {
    this.restaurante = new Restaurante();
  }

  ngOnInit() {
    this.userLogin = JSON.parse(localStorage.getItem("user"));
    this.url = GLOBAL.url;
    this.route.params.subscribe(result => {
      // this.arrayBotones = GLOBAL.arrayBotones(result.id);
      // this.id = result.id;
      this.arrayBotones = GLOBAL.arrayBotones(result.slug);
      this.slug = result.slug;
    });
    let currentUrl = window.location.href;
    if (currentUrl.indexOf("QR") != -1) {
      this.cartaQR = true;
      this.arrayBotones = GLOBAL.arrayBotonesQR(this.id);
    }

    // this.getInfoRestaurante(this.id);
    this.getInfoRestaurante(this.slug);

  }

  getInfoRestaurante(id: any) {
    // OBTENER DATOS DEL RESTAURANTE
    // this.rService.getInfoRestaurante(id).subscribe(result => {    
    this.rService.getInfoRestauranteBySlug(id).subscribe(result => {

      const hoy = new Date();
      this.diaSemanaHoy = hoy.getDay() - 1;

      if (this.diaSemanaHoy < 0) {
        this.diaSemanaHoy = 6;
      }


      this.restaurante = result;
      this.id = result.id
      this.restaurante.telefonofijo = result.telefonofijo;
      this.restaurante.telefonomovil = result.telefonomovil;
      this.restaurante.dietas = JSON.parse(result.dietas);
      this.restaurante.menu_del_dia = JSON.parse(result.menu_del_dia);
      this.restaurante.ideal_para = JSON.parse(result.ideal_para);
      this.restaurante.servicios = JSON.parse(result.servicios);
      this.restaurante.mas_servicios = JSON.parse(result.mas_servicios);
      this.restaurante.reservas = result.reservas;
      this.restaurante.take_away = result.take_away;
      this.restaurante.take_away_link = result.take_away_link;
      this.restaurante.tipo_cocina = result.tipo_cocina;
      this.restaurante.local = JSON.parse(result.local);
      this.restaurante.espacios = result.espacios;

      this.restaurante.horario = [];

      this.diasSemana.forEach((dia, index) => {
        this.restaurante.horario[index] = this.restaurante["horario_" + dia];
      });

      if (
        result.tipo_servicio == "Establecimiento de Hostelería" ||
        result.tipo_servicio == "hosteleria" ||
        result.tipo_servicio == "Hostelería"
      ) {
        this.hosteleria = true;
      } else {
        this.hosteleria = false;
      }

      this.posRestaurante = {
        lat: result.lat,
        lng: result.lng,
      }

      if (this.userLogin != null) {
        this.followService.comprobarFollowRest(this.restaurante.id, this.userLogin.id).subscribe(result => {

          if (result.result == "si") {
            this.followRestaurant = true;
          }
          if (result.result == "no") {
            this.followRestaurant = false;
          }
        });
      }

      this.addMetrica("visualizacion");

    });
  }

  addMetrica(tipo) {
    let modeloMetrica = {
      establecimiento_id: this.id
    }

    if (tipo == "visualizacion") {
      modeloMetrica["tipo_interaccion"] = 6;//visualizacion_informacion
    } else if (tipo == "llamar") {
      modeloMetrica["tipo_interaccion"] = 11;//llamar
    } else if (tipo == "click_boton_ir_A") {
      modeloMetrica["tipo_interaccion"] = 10;//click_boton_ir_A
    } else if (tipo == "clickReserva") {
      modeloMetrica["tipo_interaccion"] = 7;
    } else {
      modeloMetrica["tipo_interaccion"] = 8;
    }

    this.mService.addMetrica(modeloMetrica).subscribe(result => {
      if (result.result == "ok") {
        console.log("Guardar en metrica");
      } else {
        console.log("Error guardar en metrica");
      }
    });
  }

  onClickLikeRestaurant() {
    this.followService.setSeguidorRestaurante(this.restaurante.id, this.userLogin.id).subscribe(result => {
      this.resultInsertFollowRestaurant = result;

      if (this.resultInsertFollowRestaurant.result == "ok") {
        this.followRestaurant = true;
      }

      if (this.resultInsertFollowRestaurant.result == "delete") {
        this.followRestaurant = false;
      }
    });
  }

  onClickLike() {
    if (this.userLogin == null) {
      this.router.navigate(['registrarse'], { queryParams: { marcaFicha: this.restaurante.nombre } });
    } else {
      this.followService.setSeguidorRestaurante(this.restaurante.id, this.userLogin.id).subscribe(result => {
        this.resultInsertFollow = result;

        if (this.resultInsertFollow.result == "ok") {
          this.followRestaurant = true;
        }

        if (this.resultInsertFollow.result == "delete") {
          this.followRestaurant = false;
        }

      });
    }
  }

  navegar(direccion) {
    this.router.navigate([direccion]);
  }

  llamar(numeroTelefono) {
    window.location.href = 'tel:+34' + numeroTelefono;
    this.addMetrica("llamar");
  }

  clickIrA() {

    if (this.pos == null) {
      this.pantallaCarga = true;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };

          const url = `https://www.google.com/maps/dir/?api=1&origin=${this.pos.lat},${this.pos.lng}&destination=${this.posRestaurante.lat},${this.posRestaurante.lng}&travelmode=walking`;
          window.open(url, '_blank');
          this.pantallaCarga = false;
        }, (error) => {
          this.pantallaCarga = false;
        });
      }
    } else {
      const url = `https://www.google.com/maps/dir/?api=1&origin=${this.pos.lat},${this.pos.lng}&destination=${this.posRestaurante.lat},${this.posRestaurante.lng}&travelmode=walking`;
      window.open(url, '_blank');
    }

    this.addMetrica("click_boton_ir_A");
  }

  navegarWebsite() {

    let url = "";

    if (this.restaurante.website.indexOf("https://") !== -1) {
      url = this.restaurante.website;
    } else {
      url = "https://" + this.restaurante.website;
    }

    window.open(url, '_blank');
  }

  cambiarVerHorario() {
    this.verTodoHorario = !this.verTodoHorario;
  }

  clickReservas() {
    this.addMetrica("clickReserva");
    const url = this.restaurante.enlace_menu_del_dia;
    window.open(url, '_blank');
  }

  clickPedidos() {
    this.addMetrica("clickPedidos");
    const url = this.restaurante.take_away_link;
    window.open(url, '_blank');
  }
}
