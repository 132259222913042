import { Component, EventEmitter, OnInit, Output, ViewChild, ElementRef, Input } from '@angular/core';
import { MatSelectionList, MatListOption } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';
import * as $ from 'jquery';
import { MapaService } from '../../services/mapa.service';
import { SeccionResultadosComponent } from '../seccion-resultados/seccion-resultados.component';
import { indexOf } from 'lodash';
import internal from 'assert';
import { Filtro } from 'src/app/models/filtro';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-seccion-categorias',
  templateUrl: './seccion-categorias.component.html',
  styleUrls: ['./seccion-categorias.component.css'],
})
export class SeccionCategoriasComponent implements OnInit {
  //@ViewChild('comidas', { static: true }) comidas: MatSelectionList;

  abiertoAhora: string;
  reservaSiNo: string;
  precioMedio: any;
  localesSelected: string[];
  cocinasSelected: string[];
  comidasSelected: string[];
  totalFiltros: String[][] = [];
  countTotalfiltros: number;
  temp = [];
  reservas = [];
  valueProvincia = null;
  valueMunicipio = null;
  aplicarFiltros: Filtro;
  seleccionBusqueda: string;            // Esto recoge la selección del buscador: entre Carta, establecimiento etc....
  boolFiltro: boolean = false;
  respuestaFiltros: any;
  optionsSelect: Select2Options;
  barraDistancia: number = 0;
  distanciasTexto: string[] = ["300 m", "600 m", "1 km", "1,5 km", "2 km", "3 km", "4 km", "6 km", "8 km", "10 km",
    "15 km", "20 km", "25 km", "30 km", "35 km", "40 km", "45 km", "50 km"];

  distancias: number[] = [0.3, 0.6, 1, 1.5, 2, 3, 4, 6, 8, 10, 15, 20, 25, 30, 35, 40, 45, 50];

  distance: any;

  provincias: any[];
  municipios: any[];
  selectedSubentidad: number;
  filteredMunicipios: any[];

  @Input() filtro: Filtro;
  @Input() searchBar = '';
  @Input() item = '';

  @Output() filterSelected = new EventEmitter<Filtro>();
  @Output() filtroSeleccionado = new EventEmitter<boolean>();
  @Output() changeCloseSearch = new EventEmitter<boolean>();

  //elemento para actualizar el texto del input range de distancia 
  @ViewChild('outputRef', { static: false }) outputRef: ElementRef;

  constructor(
    private mapService: MapaService,
    private sResultados: SeccionResultadosComponent,
    private translate: TranslateService
  ) {
    this.countTotalfiltros = 0;
    $(function () { });
  }

  ngOnInit() {

    this.aplicarFiltros = this.filtro;

    this.optionsSelect = {
    }

    this.mapService.getProvincias().subscribe((provincias) => {

      let textTodas = "";

      this.translate.get("filtros.todas").subscribe((res: string) => {
        textTodas = res;
      });

      provincias.unshift({ id: "", text: textTodas });
      this.provincias = provincias;
      if (this.aplicarFiltros.selectedProvincia != undefined) {
        this.valueProvincia = JSON.parse(JSON.stringify(this.aplicarFiltros.selectedProvincia));
      }

    });
    this.mapService.getMunicipios().subscribe((municipios) => {

      let textTodos = "";

      this.translate.get("filtros.todos").subscribe((res: string) => {
        textTodos = res;
      });

      municipios.unshift({ id: '', text: textTodos });
      this.municipios = municipios;
      if (this.aplicarFiltros.selectedProvincia != undefined && this.aplicarFiltros.selectedProvincia != null && this.aplicarFiltros.selectedProvincia != 0) {
        this.onProvinciaChange({ value: this.aplicarFiltros.selectedProvincia });
        if (this.aplicarFiltros.origenDeLosProductos != undefined) {
          this.valueMunicipio = JSON.parse(JSON.stringify(this.aplicarFiltros.origenDeLosProductos));
        }

      }
    });

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        var pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };

        this.aplicarFiltros.posicionActual = pos;
      });
    }

    let distancia = parseFloat(this.aplicarFiltros.distancia);



    if (distancia != 0) {
      this.barraDistancia = this.distancias.indexOf(distancia) + 1;
    }

  }

  ngOnChanges(changes: any) {
    if (this.searchBar != "" && !changes.item) {
      this.changeCloseSearch.emit(false);
    }
  }

  ngAfterViewInit() {
    this.updateDistancia();
  }

  updateDistancia = () => {

    let textoSinLimite = "";

    this.translate.get("filtros.sin_limite").subscribe((res: string) => {
      textoSinLimite = res;
    });

    if (this.barraDistancia == undefined) {
      this.barraDistancia = 0;
      this.aplicarFiltros.distancia = "0";
      this.outputRef.nativeElement.innerHTML = textoSinLimite;
    } else {
      if (this.barraDistancia == 0) {
        this.aplicarFiltros.distancia = "0";
        this.outputRef.nativeElement.innerHTML = textoSinLimite;
      } else {
        this.aplicarFiltros.distancia = this.distancias[this.barraDistancia - 1].toString();
        this.outputRef.nativeElement.innerHTML = this.distanciasTexto[this.barraDistancia - 1];
      }
    }
  }

  getDistancia() {
    this.updateDistancia();
  }
  //oninput="distanciaOutput.value = (this.value == 0 ? 'Sin límite' : this.value + ' km')"

  eliminarFiltros(): void {

    this.valueMunicipio = undefined;
    this.valueProvincia = undefined;

    for (const key in this.filtro) {
      if (this.filtro.hasOwnProperty(key)) {
        delete this.filtro[key];
      }

      if (key == "selectedProvincia" && this.valueProvincia == "") {
        delete this.filtro[key];
      }

      if (key == "origenDeLosProductos" && this.valueMunicipio == "") {
        delete this.filtro[key];
      }

      if (key == "distancia") {
        this.filtro[key] = "0";
        this.aplicarFiltros.distancia = "0";
        this.barraDistancia = 0;
      }
    }

    this.filterSelected.emit(this.filtro);
    this.updateDistancia();
    localStorage.setItem("filtro", null);
  }

  //mostrar los municipos en el select cuando selecciones una provincia
  onProvinciaChange(value) {

    let textTodos = "";

    this.translate.get("filtros.todos").subscribe((res: string) => {
      textTodos = res;
    });

    if (value != undefined && value.value != "") {
      this.aplicarFiltros.selectedProvincia = value.value;
    } else {
      this.aplicarFiltros.selectedProvincia = this.valueProvincia;
    }

    if (this.municipios != undefined) {
      this.filteredMunicipios = this.municipios.filter(
        (municipio) => +municipio.id_provincia === +value.value
      );

      this.filteredMunicipios.unshift({ id: '', text: textTodos });

    }

  }

  onMunicipioChange(value) {
    this.aplicarFiltros.origenDeLosProductos = value.value;
  }

  changeTipoEstablecimiento(tipoEstablecimiento) {
    this.aplicarFiltros.tipoEstablecimiento = tipoEstablecimiento;
  }

}